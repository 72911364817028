<template lang="">
    <div class="card mb-0">
        <div class="card-body">
           <a href="javascript:void(0);" class="brand-logo">
                
                <img style="width: 230px; max-height: 120px;" class="text-center" src="https://api.phoenixgn.com/logo-white.png" alt="">

            </a>
            <p class="text-center text-white">Join the Phoenix Community, and make your dreams a reality</p>



            <div class="auth-login-form mt-2" >
                <div class="form-group">
                    <label for="login-email" class="form-label">Fullname *</label>
                    <input type="text" class="form-control text-white " v-model="name" placeholder="Fullname"   />
                </div>

                <div class="row">
                    <div class="col-6">
                        
                        <div class="form-group">
                            <label for="login-email" class="form-label">Username *</label>
                            <input type="text" class="form-control text-white" v-model="username" placeholder="Username"   />
                        </div>

                    </div>
                    <div class="col-6">
                         <div class="form-group">
                            <label for="login-email" class="form-label">Referral Code *</label>
                            <input type="text" class="form-control text-white"  v-model="referrer_code" placeholder="PH2123" aria-describedby="login-email" tabindex="3" autofocus />
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="login-email" class="form-label">Email *</label>
                    <input type="email" class="form-control text-white"  v-model="email" placeholder="john@example.com" aria-describedby="login-email" tabindex="2" autofocus />
                </div>

                <div class="form-group">
                    
                    <label for="login-phone" class="form-label">Phone no. *</label>
                    <input type="text" class="form-control text-white"  v-model="phone" placeholder="080********" aria-describedby="login-phone" tabindex="2" autofocus />
                </div>


                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label for="login-password">Create a password *</label>
                      
                    </div>
                    <div class="input-group input-group-merge form-password-toggle">
                        <input :type="passwordAttribute"  class="form-control text-white form-control text-white-merge" id="login-password" v-model="password" tabindex="4" placeholder=""   />
                        <div @click="togglePasswordVisibility()"  class="input-group-append">
                            <span class="input-group-text cursor-pointer"><i class="fa-solid fa-eye"></i></span>
                        </div>
                    </div>
                </div>

                
                <div class="form-group d-none">
                    <div class="d-flex justify-content-between">
                        <label for="login-password">Confirm Password</label>
                      
                    </div>
                    <div class="input-group input-group-merge form-password-toggle">
                        <input type="password" class="form-control text-white form-control text-white-merge" id="login-password" v-model="password_confirmation" tabindex="2" placeholder=""  />
                        
                    </div>
                </div>
               
                <button @click="register()" class="btn btn-primary btn-block" tabindex="4">Get Started</button>
            </div>

            <p class="text-center mt-2">
                <span>Already have an account?</span>
                <router-link  to="/login">
                    <span> Login</span>
                </router-link >
            </p>

        
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
                fullPage: false,
                name: '',
                username: '',
                email: '',
                referrer_code: '',
                phone: '',
                password: '',
                password_confirmation: '',
                  passwordAttribute: 'password'
        }
    },
    methods: {

               togglePasswordVisibility(){

   
       
            if(this.passwordAttribute == 'password'){
                this.passwordAttribute = 'text'
            }else{
                this.passwordAttribute = 'password'
            }

        },


        register(){

            // alert('https://dev.phoenixgn.com/api/register')

     
            
                      let loader = this.$loading.show({
                            // Optional parameters
                            container: this.fullPage ? null : this.$refs.formContainer,
                            canCancel: true,
                            color: '#7367F0',
                            loader: 'dots',
                            onCancel: this.onCancel,
                            opacity: 0
                            
                        });

                                axios({
                                method: "post",
                                url: process.env.VUE_APP_URL+'/api/register',
                                data: {
                                    name: this.name,
                                    username: this.username,
                                    email: this.email,
                                    phone: this.phone,

                                    referrer_code: this.referrer_code,
                                    password: this.password
                                },
                                headers: {
                                    'Access-Control-Allow-Origin': '*',
                                    'Content-type': 'application/json',
                                    'Accept': 'application/json',
                                },
                                
                                })
                                .then( (response) =>{
                                    //handle success
                                        console.log(response);

                                    if(response.data.access_token){

                                                           console.log(response)

                                   
                                        localStorage.setItem('user_role', response.data.user_data.role);
                                        localStorage.setItem('user_token', response.data.access_token);
                                        localStorage.setItem('user_data', JSON.stringify(response.data.user_data));

                                    
                                    loader.hide()

                                     this.$vToastify.success({
                                        'body': 'Signup Successful.',
                                        'iconEnabled': true,
                                        'defaultTitle': false,
                                        'hideProgressbar': true                                        
                                    });

                                    return this.$router.push('/verify')

                                   

                                      

                                    }else{

                                            loader.hide()

                                         response.data['name'] ? 
                                     this.$vToastify.error({
                                        'body': response.data['name'][0],
                                        'iconEnabled': true,
                                        'defaultTitle': false,
                                        'hideProgressbar': true                                        
                                    }): ''
                                             response.data['email'] ? 
                                     this.$vToastify.error({
                                        'body': response.data['email'][0],
                                        'iconEnabled': true,
                                        'defaultTitle': false,
                                        'hideProgressbar': true                                        
                                    }): ''
                                             response.data['username'] ? 
                                     this.$vToastify.error({
                                        'body': response.data['username'][0],
                                        'iconEnabled': true,
                                        'defaultTitle': false,
                                        'hideProgressbar': true                                        
                                    }): ''
                                             response.data['referrer_code'] ? 
                                     this.$vToastify.error({
                                        'body': response.data['referrer_code'][0],
                                        'iconEnabled': true,
                                        'defaultTitle': false,
                                        'hideProgressbar': true                                        
                                    }): ''
                                             response.data['password'] ? 
                                     this.$vToastify.error({
                                        'body': response.data['password'][0],
                                        'iconEnabled': true,
                                        'defaultTitle': false,
                                        'hideProgressbar': true                                        
                                    }): ''

                                    if(response.data.package_error)
                                         this.$vToastify.error({
                                        'body': response.data.package_error,
                                        'iconEnabled': true,
                                        'defaultTitle': false,
                                        'hideProgressbar': true                                        
                                    })
                                    }

                    
        
                                })
                                .catch( (response)=> {

                                    // alert(response);
                                    //handle error
                                    console.log(response);

                                    this.$vToastify.error({
                                        'body': 'An error occured',
                                        'iconEnabled': true,
                                        'defaultTitle': false,
                                        'hideProgressbar': true
                                    });

                                    loader.hide()

                                });
            
        
        }
    },
    mounted(){
        console.log(this.$route.params.id)
        this.referrer_code = this.$route.params.id
    }
}
</script>

<style scoped>

</style>