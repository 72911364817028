<template>
<div class="">

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Notifications</a>
                            </li>
                            <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
    <div  class="content-body">


        <div class="list-group ">
            <a v-for="notification in notifications" :key="notification.index" href="#" class="list-group-item list-group-item-action  mb-3 bg-primar text-white">
                <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1 text-warning">🔔 {{notification.title}}</h5>
            
                </div>
                <p class="mb-1">{{notification.log}}</p>
                <small class="badge badge-secondary">{{formatDate(notification.created_at)}}</small>
            </a>
        
        </div>
        
    </div>

</div>


</template>


<script>
import axios from 'axios'
export default {

    data() {
        return {
            notifications: []
        }
    },
    methods: {

        formatDate(dateString){

            var date = new Date(dateString);

            var options = {
                month: 'long',
                year: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true
            };

            

            return date.toLocaleDateString(undefined, options);
        },
        getNotifications(){

            axios({
                url: process.env.VUE_APP_URL +'/api/notifications',
                method: 'get',
                params:{
                    read: 1
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },


            })
            .then((response)=>{
                this.notifications = response.data
                console.log(response)
            })
            .catch((response)=>{
                console.log(response)
            })

        }
    },
    mounted() {
        this.getNotifications()
    },
}
</script>