<template>
<div class="">

            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h2 class="content-header-title float-left mb-0">Shop</h2>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="index.html">Home</a>
                                    </li>
                                    
                                    <li class="breadcrumb-item active">Shop
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                    <div class="form-group breadcrumb-right">
                        <div class="dropdown">
                            <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i data-feather="grid"></i></button>
                            <div class="dropdown-menu dropdown-menu-right"><a class="dropdown-item" href=""><i class="mr-1" data-feather="check-square"></i><span class="align-middle">Todo</span></a><a class="dropdown-item" href="app-chat.html"><i class="mr-1" data-feather="message-square"></i><span class="align-middle">Chat</span></a><a class="dropdown-item" href="app-email.html"><i class="mr-1" data-feather="mail"></i><span class="align-middle">Email</span></a><a class="dropdown-item" href="app-calendar.html"><i class="mr-1" data-feather="calendar"></i><span class="align-middle">Calendar</span></a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-detached ">
                <div class="content-body">
                    <!-- E-commerce Content Section Starts -->
             
                    <!-- E-commerce Content Section Starts -->

                    <!-- background Overlay when sidebar is shown  starts-->
                   
                    <!-- background Overlay when sidebar is shown  ends-->

                    <!-- E-commerce Search Bar Starts -->
                   
                    <!-- E-commerce Search Bar Ends -->

                    <!-- E-commerce Products Starts -->
                    <section>
                        <div class="row">
                            <div  class=" col-md-4  p-3">

                                <div class="card ecommerce-card">
                                    <div class="item-img text-center">
                                        <a href="app-ecommerce-details.html">
                                            <img class="img-fluid card-img-top" style="max-width: 230px;" :src="product.featured_image" alt="img-placeholder" /></a>
                                    </div>
                                    <div class="card-body">
                                        <div class="item-wrapper">
                                            
                                            <div>
                                            
                                            </div>
                                        </div>
                                        <h6 class="item-name">
                                           {{product.name}}
                                        
                                        </h6>
                                        <p class="card-text item-description">
                                           
                                        </p>
                                    </div>
                                    <div class="item-options text-center">
                                        <div class="item-wrapper">
                                        
                                        </div>
                                        
                                        
                                    </div>

                                </div>
                            </div>
                            
                                <div class="container">
                                    <h3 class=" py-2 ">Select Quantity</h3>
                                    <div class="form-group">
                                        <input style="font-size: 23pt;" v-model="quantity" @change="selectQty"  id="QtyListener"  type="number" class="text-center form-control form-control-lg col-md-4 ">
                                    </div>
                                </div>

                            <div class="col-md-8 pt-md-5">

                                <h3 class=" py-2 ">Select Size</h3>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div @click="selectOption(1, 5, 12)" class="card  entry">
                                            <div  class="card-body">
                                                <input id="1" type="radio" name="list" />
                                                <div class="highlight"></div>
                                                <h6 class="text-center">5ml - PACK (12 PCS)</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div @click="selectOption(2, 10, 12)" class="card entry">
                                            <div class="card-body">
                                                <input id="2" type="radio" name="list" />
                                                <div class="highlight"></div>
                                                <h6 class="text-center">10ml - PACK (12 PCS)</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div @click="selectOption(3, 20, 1)" class="card entry">
                                            <div class="card-body">
                                                <input id="3" type="radio" name="list" />
                                                <div class="highlight"></div>
                                                <h6 class="text-center">20ml (SINGLE)</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div @click="selectOption(4, 30, 1)" class="card entry">
                                            <div class="card-body">
                                                <input id="4" type="radio" name="list" />
                                                <div class="highlight"></div>
                                                <h6 class="text-center">30ml (SINGLE)</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div @click="selectOption(5, 100, 1)" class="card entry">
                                            <div class="card-body">
                                                <input id="5" type="radio" name="list" />
                                                <div class="highlight"></div>
                                                <h6 class="text-center">100ml (SINGLE)</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="container ">
                                    
                                    <!-- :split_code="'SPL_UMfLowpW9Z'" -->
                                    <paystack :class="'btn btn-success' "  
                                        :paystackkey="paystackkey"
                                        :email="email" 
                                        :amount="amount" 
                                        :reference="reference" 
                                        :onSuccess="onSuccessfulPayment"
                                        :onCanel="onCancelledPayment"
                                        :callback="callback"
                                        :close="close"
                                        :embed="false"
                                        >
                                        Proceed to pay N {{format(amount/100)}}
                                    </paystack>
                                </div>
                            </div>

                        </div>
                       
                    </section>
                    <!-- E-commerce Products Ends -->

                    <!-- E-commerce Pagination Starts -->
                    
                    <!-- E-commerce Pagination Ends -->

                </div>
            </div>


<div>
    
</div>
  

</div>


</template>


<script>
import axios from 'axios'
import paystack from "vue-paystack";


export default {
    components:{
         paystack,
    },

    data() {
        return {
            product: '',
            quantity: 1,
            paystackkey: "pk_test_99318e2132c0e2aa4bfa8dc843f109867e3840e6", //paystack public key
            email: "foobar@example.com", // Customer email
            amount: 0, // in kobo
            userData: '',
            package_id: '',
            selected_package_id: '',
            activating: false,
            payment_proof:'',
            payment_proof_data: '',
            unit_price: '',
            ml: 5,
            pcs: 12
            
        }
    },
    computed: {
    reference() {
        
      
        let text = "";
        let possible =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < 10; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
        },
    },

    methods: {

        onSuccessfulPayment: function (response) {

            console.log(response)
        },

        onCancelledPayment: function (response) {

            console.log(response)
        },


        format(value){
            var numeral = require('numeral');

            return numeral(value).format('N 0,0.00')
        },

        selectQty(event){
            // this.quantity = qty;
            // return this.quantity;

           console.log(event.target.value)

           this.amount = this.unit_price * this.ml * this.pcs * event.target.value * 100


            // console.log(this.amount)

           

            
            return this.amount
        },
        async getProducts(){

            // alert(this.$route.params.id)

           await axios({
                url: process.env.VUE_APP_URL +'/api/products',
                method: 'get',
                params:{
                    product_id: this.$route.params.id
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },


            })
            .then((response)=>{
                this.product = response.data
                this.unit_price = response.data.price

                this.getUserProfile()

                console.log(response)
            })
            .catch((response)=>{
                console.log(response)
            })

        },
        callback: async function () {

            // console.log()

            await axios({
                url: process.env.VUE_APP_URL+'/api/reorder_point_reward',
                method: 'get',
                params:{

                    product_id : this.product.id, 
                    user_id: this.userData.id,
                    quantity: this.quantity,
                    ml: this.ml,
                    pcs: this.pcs
                

                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },


            })
            .then((response)=>{

            console.log(response)

            return this.$router.push('/user/purchase-success');


            })
            .catch((response)=>{

                console.log(response)
            })



        },
        
        close: function () {
            console.log("Payment closed");
            if (localStorage.getItem('reloaded')) {
                // The page was just reloaded. Clear the value from local storage
                // so that it will reload the next time this page is visited.
                localStorage.removeItem('reloaded');
                } else {
                    // Set a flag so that we know not to reload the page twice.
                    localStorage.setItem('reloaded', '1');
                    location.reload();
                }
        },

        getUserProfile(){
             

             axios({
                url: process.env.VUE_APP_URL+'/api/profiles',
                method: 'get',
                 headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },
            })
            .then((response)=>{

                this.userData = response.data

                this.email = response.data.email

                console.log(this.userData.email)

                // alert(this.userData.email)


            })
            .catch((response)=>{

                console.log(response)
            })


        },

        selectOption(id, ml, pcs){

            this.ml = ml
            
            this.pcs = pcs

            this.amount = this.unit_price * ml * pcs * this.quantity * 100

            console.log(this.amount)

            document.getElementById(id).click();

            
            return this.amount
   
        },
    },


    mounted() {
        this.getProducts()
       // this.getUserProfile()
        
        // this.getStudentReg()
    },
    
}
</script>
<style scoped>
.row .card:hover{
    outline: 4px solid green;
    border-radius: 4px;
}

div.entry {
    position: relative;

}
div.entry div.highlight {
    
    position: absolute;
    z-index: -1;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
div.entry input:checked ~ div.highlight {
    outline: 4px solid green;
    border-radius: 4px;
}
</style>