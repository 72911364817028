<template >
    <div class="card mb-0">
        <div class="card-body">
            <a href="javascript:void(0);" class="brand-logo">
                
                <img style="width: 230px; max-height: 120px;" class="text-center" src="https://api.phoenixgn.com/logo-white.png" alt="">
            </a>

            <h4 class="card-title mb-1">Welcome to Phoenixgn! 👋</h4>
            <p class="card-text mb-2">Please sign-in to your account and start the adventure</p>

            <div class="auth-login-form mt-2" >
                <div class="form-group">
                    <label for="login-email" class="form-label">Email</label>
                    <input type="text" class="form-control" id="login-email" v-model="email" placeholder="john@example.com" aria-describedby="login-email" tabindex="1" autofocus />
                </div>

                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label for="login-password">Password</label>
                        <router-link :to="'/forgot-password'">
                            <small>Forgot Password?</small>
                        </router-link>
                    </div>
                    <div class="input-group input-group-merge form-password-toggle">
                        <input class="form-control form-control-merge" :type="passwordAttribute" id="login-password" v-model="password" tabindex="2" placeholder="" aria-describedby="login-password" />
                        <div @click="togglePasswordVisibility()" class="input-group-append">
                            <span class="input-group-text cursor-pointer"><i class="fa-solid fa-eye"></i></span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" id="remember-me" tabindex="3" />
                        <label class="custom-control-label" for="remember-me"> Remember Me </label>
                    </div>
                </div>
                <button @click="login()" class="btn btn-primary btn-block" tabindex="4">Sign in</button>
            </div>

            <p class="text-center mt-2">
                <span>New to Phoenixgn?</span>
                <router-link to="/register">
                    <span>Create an account</span>
                </router-link>
            </p>

        
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            fullPage: false,
            email: '',
            password: '',
            passwordAttribute: 'password'
        }
    },

    methods: {

        togglePasswordVisibility(){

   
       
            if(this.passwordAttribute == 'password'){
                this.passwordAttribute = 'text'
            }else{
                this.passwordAttribute = 'password'
            }

        },

        login(){

                let loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: true,
                  color: '#7367F0',
                  loader: 'bars',
                  onCancel: this.onCancel,
                  opacity: 0.3
                });

              
                // // simulate AJAX
                // setTimeout(() => {
                //   loader.hide()
                // },5000) 

                axios({
                    method: "post",
                    url: process.env.VUE_APP_URL+'/api/login',
                    data: {
                        email: this.email,
                        password: this.password,
                    }
                })
                .then((response)=>{
                    
                    console.log(response)

                    //  this.$router.push('/user/home')

                    localStorage.setItem('user_role', response.data.user_data.role);
                    localStorage.setItem('user_token', response.data.access_token);
                    localStorage.setItem('user_data', JSON.stringify(response.data.user_data));


                    if(response.data.user_data.role == 'user'){

                        // alert('user')

                        loader.hide()

                         this.$vToastify.success({
                            'body': 'Login Successful',
                            'iconEnabled': true,
                            'defaultTitle': false,
                            'hideProgressbar': true                                        
                        });

                        return this.$router.push('/user/home')

                    }

                    if (response.data.user_data.role == 'admin') {
                        loader.hide()

                         this.$vToastify.success({
                            'body': 'Login Successful',
                            'iconEnabled': true,
                            'defaultTitle': false,
                            'hideProgressbar': true                                        
                        });

                        return this.$router.push('/admin/home')
                    }
                    
                    
                    
                    else{

                        this.$vToastify.error({
                            'body': 'Invalid Credentials',
                            'iconEnabled': true,
                            'defaultTitle': false,
                            'hideProgressbar': true                                        
                        });

                        return this.$router.push('/login')
                    }

                })
                .catch((response)=>{
                    
                      loader.hide()
                        this.$vToastify.error({
                            'body': 'Invalid Credentials',
                            'iconEnabled': true,
                            'defaultTitle': false,
                            'hideProgressbar': true                                        
                        });

                         console.log(response)

                        return this.$router.push('/login')
                

                })

                


        
        }
    },
}
</script>